// Action types.
//------------------------
export default {
  // Store clean
  STORE_CLEAN: "store_clean",

  // Language
  SET_LANGUAGE: "set_language",

  // UI
  UI_SET_ERROR: "ui_set_error",
  UI_UNSET_ERROR: "ui_unset_error",
  UI_START_LOADING: "ui_start_loading",
  UI_END_LOADING: "ui_end_loading",
  UI_SET_BOOK_SECTION_LIST: "ui_set_book_section_list",
  UI_SET_DEMARCACION_LIST: "ui_set_demarcacion_list",
  UI_SET_COURSE_TYPE_LIST: "ui_set_course_type_list",
  UI_SET_DIPLOMA_TIPO_LIST: "ui_set_diploma_tipo_list",
  UI_SET_STATUS_TASK_LIST: "ui_set_status_task_list",
  UI_SET_ROLE_LIST: "ui_set_role_list",
  UI_SET_ORGANIZADOR_LIST: "ui_set_organizador_list",
  UI_SET_FORMADOR_LIST: "ui_set_formador_list",
  UI_SET_CUSTOMER_LIST: "ui_set_customer_list",
  UI_SET_CENTER_LIST: "ui_set_center_list",
  UI_SET_TRAINER_LIST: "ui_set_trainer_list",
  UI_SET_ROOM_LIST: "ui_set_room_list",
  UI_SET_KPI_LIST: "ui_set_kpi_list",

  // Autenticacion
  AUTH_CHECKED: "[auth] Checked",
  AUTH_START_LOGIN: "[auth] Start login",
  AUTH_LOGIN: "[auth] Login",
  AUTH_START_TOKEN_RENEW: "[auth] Start token renew",
  AUTH_LOGOUT: "[auth] Logout",

  // Demarcaciones
  SET_AREAS: "set_areas",
  SELECT_AREA: "select_area",

  // Biblioteca
  SET_BOOK_SECTIONS: "set_book_sections",
  SELECT_BOOK_SECTION: "select_book_section",
  SET_BOOK_SECTION_FILTER: "set_book_section_filter",

  SET_BOOK_MEMBERS: "set_book_members",
  SET_BOOK_MEMBER_FILTER: "set_book_member_filter",

  SET_BOOKS: "set_books",
  SELECT_BOOK: "select_book",
  SET_BOOK_FILTER: "set_book_filter",

  // Documentos
  SET_DOCUMENTS: "set_documents",
  SELECT_DOCUMENT: "select_document",
  SET_DOCUMENT_FILTER: "set_document_filter",

  // Documentos de cursos
  SET_COURSE_DOCUMENTS: "set_course_documents",
  SELECT_COURSE_DOCUMENT: "select_course_document",
  SET_COURSE_DOCUMENT_FILTER: "set_course_document_filter",

  // Documentos de alumnos
  SET_STUDENT_DOCUMENTS: "set_student_documents",
  SELECT_STUDENT_DOCUMENT: "select_student_document",
  SET_STUDENT_DOCUMENT_FILTER: "set_student_document_filter",

  // Alumnos
  SET_STUDENTS: "set_students",
  SELECT_STUDENT: "select_student",
  SET_STUDENT_FILTER: "set_student_filter",
  SET_STUDENT_TITULACIONES: "set_student_titulaciones",

  // Cursos
  SET_COURSES: "set_courses",
  SET_FILES_DEALS: "set_files_deals",
  SET_NOTES_DEALS: "set_notes_deals",
  SELECT_COURSE: "select_course",
  SET_COURSE_FILTER: "set_course_filter",

  // Diplomas
  SET_DIPLOMAS: "set_diplomas",
  SELECT_DIPLOMA: "select_diploma",
  SET_DIPLOMA_FILTER: "set_diploma_filter",

  // Diplomas Alumnos
  SET_STUDENT_DIPLOMAS: "set_student_diplomas",
  SELECT_STUDENT_DIPLOMA: "select_student_diploma",
  SET_STUDENT_DIPLOMA_FILTER: "set_student_diploma_filter",

  // Tareas
  SET_TASKS: "set_tasks",
  SELECT_TASK: "select_task",
  SET_TASK_FILTER: "set_task_filter",

  // Tareas de alumnos
  SET_STUDENT_TASKS: "set_student_tasks",
  SELECT_STUDENT_TASK: "select_student_task",
  SET_STUDENT_TASK_FILTER: "set_student_task_filter",

  // Documentos de tareas de alumnos
  SET_STUDENT_TASK_DOCUMENTS: "set_student_task_documents",

  // Usuarios
  SET_USERS: "set_users",
  SELECT_USER: "select_user",
  SET_USER_FILTER: "set_user_filter",

  // Organizadores
  SET_ORGANIZERS: "set_organizers",
  SELECT_ORGANIZER: "select_organizer",
  SET_ORGANIZER_FILTER: "set_organizer_filter",

  //Clientes
  SET_CUSTOMERS: "set_customers",
  SET_CUSTOMERS_LIST: "set_customers_list",
  SELECT_CUSTOMER_LIST: "select_customer_list",
  SELECT_CUSTOMER: "select_customer",
  SET_CUSTOMER_FILTER: "set_customer_filter",

  // Formadores Homologados
  SET_TRAINERS: "set_trainers",
  SET_TRAINER_HOURS: "set_trainer_hours",
  SELECT_TRAINER: "select_trainer",
  SET_TRAINER_FILTER: "set_trainer_filter",

  // Titulaciones de alumnos
  SET_STUDENT_DEGREES: "set_student_degrees",
  SELECT_STUDENT_DEGREE: "select_student_degree",
  SET_STUDENT_DEGREE_FILTER: "set_student_degree_filter",

  // Inscripciones a cursos
  SET_STUDENT_COURSES: "set_student_courses",
  SELECT_STUDENT_COURSE: "select_student_course",
  SET_STUDENT_COURSE_FILTER: "set_student_course_filter",
  SET_STUDENT_COURSE_DONE: "set_student_course_done",

  // Alumnos inscritos
  SET_COURSE_STUDENTS: "set_course_students",

  // Planificador
  SET_EVENTS: "set_events",
  SELECT_EVENT: "select_event",
  SET_EVENT_FILTER: "set_event_filter",
  SET_OPEN_FORMATION_DEALS: "set_open_formation_deals",
  SET_PLAN_CUSTOMERS: "set_plan_customers",
  SELECT_PLAN_CUSTOMER: "select_plan_customer",
  SET_PLAN_CUSTOMER_FILTER: "set_plan_customer_filter",
  SET_OCCUPATIONS: "set_occupations",
  SELECT_OCCUPATION: "select_occupation",
  SET_OCCUPATION_FILTER: "set_occupation_filter",
  SET_TRAINER_AVAILABILITY_DAYS: "set_trainer_availability_days",
  SET_TRAINER_AVAILABILITY_DAYS_FILTER: "set_trainer_availability_days_filter",
  STORE_CLEANUP: "store_cleanup",
};

