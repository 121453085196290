import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import M from 'materialize-css';

import FormButton from '../../components/UI/FormButton';
import { renderSelectFieldTwoColumns } from '../../components/UI/Fileds';

import { fetchTrainerList } from "../../actions/ui";
import { setTrainerFilter } from '../../actions/trainer';

import { fetchTrainersHours } from '../../actions/trainer';

const TrainerHoursSearchForm = ({ submitting, handleSubmit }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(true);  // Estado para controlar apertura

    const { filter, trainerList } = useSelector(state => ({
        filter: state.trainer.filter,
        trainerList: state.ui.trainerList || []
    }));

    useEffect(() => {
        dispatch(initialize('trainerHoursSearchForm', filter));
        dispatch(fetchTrainerList()); // Cargar formadores al montar
    }, [dispatch, filter]);

    useEffect(() => {
        const collapsibles = document.querySelectorAll('.collapsible-search-form');
        if (collapsibles.length > 0) {
            M.Collapsible.init(collapsibles, { accordion: false });
    
            // 🔥 Abre el collapsible por defecto
            const instance = M.Collapsible.getInstance(collapsibles[0]);
            if (instance) instance.open(0);
        }
    
        dispatch(initialize('trainerHoursSearchForm', filter));
        dispatch(fetchTrainerList()); // Cargar formadores al montar
    }, [dispatch, filter]);
    
    

    useEffect(() => {
        const elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }, [trainerList]);

    const handleReset = () => {
        dispatch(setTrainerFilter({}));
        dispatch(initialize('trainerHoursSearchForm', {}));
    };

    const handleSearch = (values) => {
        const collapsibles = document.getElementsByClassName('collapsible-search-form');
        const instance = M.Collapsible.getInstance(collapsibles[0]);
        instance.close();
    
        // Convertimos trainer_id en email antes de hacer la búsqueda
        const selectedTrainer = trainerList.find(trainer => trainer.id === values.trainer_id);
        const email = selectedTrainer ? selectedTrainer.email : '';
    
        dispatch(setTrainerFilter(values));
        dispatch(fetchTrainersHours({ email })); // 🔥 Llamamos la API con email en lugar de trainer_id
    };
    

    const renderForm = () => (
        <form className="search-form" onSubmit={handleSubmit(handleSearch)}>
            <div className="row margin">               
                <Field
                    name="trainer_id"
                    id="trainer_id"
                    type="select"
                    label={t("Formador")}
                    component={renderSelectFieldTwoColumns}
                    icon="people"
                    key="trainer_id"                    
                >
                    <option value="" disabled>
                        {t("form-select-choose-one")}
                    </option>
                    {(Array.isArray(trainerList) ? trainerList : Object.values(trainerList))
                        .sort((a, b) => (a.label || "").localeCompare(b.label || ""))
                        .map((trainer) => (
                            <option value={trainer.id} key={trainer.id}>
                                {trainer.label}
                            </option>
                        ))}
                </Field>
            </div>

            <div className="row">
                <FormButton type="submit"
                    title={t('search-form-button-title')}
                    disabled={submitting}
                />
                <FormButton type="reset"
                    title={t('reset-form-button-title')}
                    disabled={submitting}
                    onClick={handleReset}
                />
                <div className="input-field col s12"></div>
            </div>
        </form>
    );

    return (
        <ul className="collapsible popout collapsible-search-form">
            <li className={isExpanded ? "active" : ""}>  {/* 🔥 Asegurar que inicie abierto */}
                <div className="collapsible-header">
                    <i className="material-icons">tune</i> {t('filter-title')}
                </div>
                <div className="collapsible-body">
                    {renderForm()}
                </div>
            </li>
        </ul>
    );
};

const validate = (values) => ({});

export default reduxForm({
    form: 'trainerHoursSearchForm',
    validate: validate,
    destroyOnUnmount: false
})(TrainerHoursSearchForm);
