import React from "react";
import { Button, Grid } from "@material-ui/core";

const CustomToolbar = (toolbar) => {
  const { label, onView, onNavigate } = toolbar;

  const navigate = (action) => {
    onNavigate(action);
  };

  const view = (viewName) => {
    onView(viewName);
  };

  return (
    <div className="rbc-toolbar">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Button onClick={() => navigate("TODAY")}>Today</Button>
          <Button onClick={() => navigate("PREV")}>Prev</Button>
          <Button onClick={() => navigate("NEXT")}>Next</Button>
        </Grid>
        <Grid item>
          <span className="rbc-toolbar-label">{label}</span>
        </Grid>
        <Grid item>
          <Button onClick={() => view("month")}>Month</Button>
          <Button onClick={() => view("week")}>Week</Button>
          <Button onClick={() => view("day")}>Day</Button>
          <Button onClick={() => view("agenda")}>Agenda</Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomToolbar;
