import TYPES from "./types";
import { apiClientWithToken } from "../services/backend";
import { tr } from "date-fns/locale";

export const fetchTrainerHours = (event_id) => {
  return async (dispatch) => {
    try {
      const timestamp = Date.now();
      const res = await apiClientWithToken().get(`/trainer-hours/?timestamp=${timestamp}`, {
        params: { event_id },
      });
      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        // Despachar la acción con los datos obtenidos
        dispatch(setTrainerHours(data));
        return { ok: true, message: data.message, data: data };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      // Manejar el error y devolver un mensaje de error
      console.error('Error fetching trainer hours:', error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

// actions.js
export const setTrainerHours = (data) => ({
  type: 'SET_TRAINER_HOURS',
  payload: data,
});

export const sendTrainerHour = (trainerHour, event, trainerId) => {
  return async () => {
    try {
      let res;
      if (trainerHour.id) {
        //si trainerHourParams.check_in es null, no se envía
        const trainerHourUpdate = {};
              
        if (trainerHour.check_in === null) {
          trainerHourUpdate.check_out = trainerHour.check_out;
        }
        if (trainerHour.check_out === null) {
          trainerHourUpdate.check_in = trainerHour.check_in;
        }
        if (trainerHour.check_in !== null && trainerHour.check_out !== null) {
          trainerHourUpdate.check_in = trainerHour.check_in;
          trainerHourUpdate.check_out = trainerHour.check_out;
        }

        res = await apiClientWithToken().put(`/trainer-hours/${trainerHour.id}`, trainerHourUpdate);
      } else {
        const trainerHourParams = {
          ...trainerHour,
          event_id: event.id,
          trainer_id: trainerId
        };

        res = await apiClientWithToken().post("/trainer-hours", trainerHourParams);
      }

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      return { ok: false, message: 'Error al actualizar.' };
    }
  };
};

export const fetchEvents = (filter) => {
  return async (dispatch) => {
    try {
      const timestamp = Date.now(); // replace with your timestamp if needed
      const res = await apiClientWithToken().get(`/events/?timestamp=${timestamp}`, {
        params: { ...filter },
      });
      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setEvents(data));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      return { ok: false, message: "message-server-error" };
    }
  };
};

const setEvents = (data) => ({
  type: TYPES.SET_EVENTS,
  payload: data,
});

export const selectEvent = (data) => ({
  type: TYPES.SELECT_EVENT,
  payload: data,
});

export const setEventFilter = (filter) => ({
  type: TYPES.SET_EVENT_FILTER,
  payload: filter,
});

export const deleteEvent = (id) => {
  return async () => {
    try {
      const res = await apiClientWithToken().delete(`/events/${id}`);

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      return { ok: false, message: "message-server-error" };
    }
  };
};
// fetch customers /customers/list

export const fetchPlanCustomers = (filter) => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get("/customers/list", {
        params: { ...filter },
      });
      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setPlanCustomers(data));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      return { ok: false, message: "message-server-errorx" };
    }
  };
};

const setPlanCustomers = (data) => ({
  type: TYPES.SET_PLAN_CUSTOMERS,
  payload: data,
});

export const selectPlanCustomer = (data) => ({
  type: TYPES.SELECT_PLAN_CUSTOMER,
  payload: data,
});

export const setPlanCustomerFilter = (filter) => ({
  type: TYPES.SET_PLAN_CUSTOMER_FILTER,
  payload: filter,
});

export const sendEvent = (event) => {
  return async () => {
    try {
      let res;

      if (event.id) {
        res = await apiClientWithToken().put(`/events/${event.id}`, event);
      } else {
        res = await apiClientWithToken().post("/events", event);
      }

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      return { ok: false, message: "message-server-error" };
    }
  };
};

// Fetch occupations by center and room
export const fetchOccupations = (filter) => {
  return async (dispatch) => {
    try {
      const timestamp = Date.now(); // replace with your timestamp if needed
      const res = await apiClientWithToken().get(`/events/?timestamp=${timestamp}`, {
        params: { ...filter },
      });
      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setOccupations(data));
        return { ok: true, message: data.message, data: data };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      return { ok: false, message: "message-server-error" };
    }
  };
};

const setOccupations = (data) => ({
  type: TYPES.SET_OCCUPATIONS,
  payload: data,
});

export const setOccupationsFilter = (filter) => ({
  type: TYPES.SET_OCCUPATIONS_FILTER,
  payload: filter,
});

// Delete Logicall Event
export const deleteLogicallEvent = (event) => {
  return async () => {
    try {
      if (!event.id) {
        return { ok: false, message: "message-server-error" };
      }
      const res = await apiClientWithToken().put(`/events/${event.id}`, event);
      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: 'message-delete-session-text' };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      return { ok: false, message: "message-server-error" };
    }
  };
};

// envio confirmar formador
export const confirmarFormador = (event_id, trainerId, tokenFormador) => {
  return async () => {
    try {
      if (!event_id) {
        return { ok: false, message: "message-server-error" };
      }
      const res = await apiClientWithToken().post(`events/enviarConfirmacionFormador/${event_id}`,
        {
          trainerId: trainerId,
          tokenFormador: tokenFormador
        }
      );
      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: 'Email enviado correctamente.' };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      return { ok: false, message: "message-server-error" };
    }
  };
};

// Planner all sessions
export const plannerAll = (curso_id) => {
  return async () => {
    try {
      if (!curso_id) {
        return { ok: false, message: "message-server-error" };
      }
      const res = await apiClientWithToken().post(`events/addPlanificacionesByCurso/${curso_id}`);
      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: 'La formación se ha planificado correctamente. Se ha enviado un email.' };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      return { ok: false, message: "message-server-error" };
    }
  };
};

const setOpenFormationDeals = (data) => ({
  type: TYPES.SET_OPEN_FORMATION_DEALS,
  payload: data,
});

export const fetchOpenFormationDeals = (filter) => {
  return async (dispatch) => {
    try {
      const timestamp = Date.now();
      const res = await apiClientWithToken().get(`/cursos/?timestamp=${timestamp}`, {
        params: { ...filter },
      });
      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        const courses = data.list;
        dispatch(setOpenFormationDeals(courses));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setTrainerAvailabilityDaysFilter = (filter) => ({
  type: TYPES.SET_TRAINER_AVAILABILITY_DAYS_FILTER,
  payload: filter,
});

export const fetchTrainerAvailabilityDays = (filter = {}) => {
  return async (dispatch) => {
    try {
      const timestamp = Date.now();
      const res = await apiClientWithToken().get(`/disponibilidad-trainers/list?timestamp=${timestamp}`, {
        params: { ...filter },
      });
      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        const availabilityDays = Array.isArray(data.list) ? data.list : [];
        dispatch(setTrainerAvailabilityDays(availabilityDays));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      return { ok: false, message: "message-server-error" };
    }
  };
};

// Edit trainer availability days
export const editTrainerAvailabilityDays = (availabilityDays, trainer_id) => {
  return async () => {
    try {
      const res = await apiClientWithToken().post("/disponibilidad-trainers", {
        dates: availabilityDays,
        trainer_id: trainer_id
      });

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      return { ok: false, message: "message-server-error" };
    }
  };
};

// Delete trainer availability days
export const deleteTrainerAvailabilityDays = (availabilityDays, trainer_id) => {
  return async () => {
    try {
      const res = await apiClientWithToken().delete("/disponibilidad-trainers/123", {
        data: { dates: availabilityDays, trainer_id: trainer_id },

      });

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      return { ok: false, message: "message-server-error" };
    }
  };
};
const setTrainerAvailabilityDays = (days) => ({
  type: TYPES.SET_TRAINER_AVAILABILITY_DAYS,
  payload: days
});

export const storeCleanup = () => ({
  type: TYPES.STORE_CLEANUP,
});
