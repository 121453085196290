import React, { useState, useEffect, useMemo } from "react";
import { Form, Modal, Button } from "antd";
import { EditOutlined, CommentOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import CommentListPanel from "./CommentListPanel";
import CommentPanel from "./CommentPanel";
import Swal from "sweetalert2";


import { showConfirmation, showError } from "../../../helpers/messages";

const CommentsTimelineModal = ({
  isOpen,
  onClose,
  commentsEvent,
  onSubmit,
  saveCommentToDB,
  onDeleted,
  author,
  sessionIndex,
  eventId,
  sesionId,
  modalKey
}) => {

  const [comments, setComments] = useState(commentsEvent || []);
  const [form] = Form.useForm();
  const [isInputVisible, setInputVisible] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [editingComment, setEditingComment] = useState(""); // New state variable
  const { user } = useSelector((state) => state.auth);
  const [visibilidadFormador, setVisibilidadFormador] = useState(false);

  useEffect(() => {

    const fetchComments = async () => {
      try {
        //Ejecutar siempre que se abra el modal y sesionId sea diferente de null
        if (!sesionId || eventId != null || eventId !== undefined) {
          const timestamp = new Date().getTime();
          const response = await fetch(`https://api.jardm.site/api/events/getEventComment/${sesionId}?timestamp=${timestamp}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          const observacionesList = JSON.parse(data.result.data.list[0].observaciones);
          // si tiene rol formador entonces solo mostrar los comentarios que son visibles para el formador. campo visibilidadFormador = true
          const hasRoleFormador = user.roles.some(role => role.code === 'ROLE_FORMADOR');
          if (hasRoleFormador) {
            const filteredComments = observacionesList.filter(comment => comment.visibilidadFormador === true);
            setComments(filteredComments);
          } else {
            setComments(observacionesList);
          }
        }
      } catch (error) {
        console.error('There was a problem with your fetch operation for comments:', error);
      } finally {

      }

    };
    fetchComments();
  }, [eventId, modalKey]);

  const title = isNaN(sessionIndex)
    ? sessionIndex
    : `Sesión Número: ${sessionIndex + 1}`;

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      setInputVisible(false);
      setCommentText("");
      setEditingComment(null); // Reset editing comment when modal is closed
    }
  }, [isOpen, form]);


  const validateAndSubmit = async () => {
    try {
      //setInputVisible(true);
      const newComment = {
        id: editingComment ? editingComment.id : uuidv4(), // Use existing id if editing, else generate new id
        text: commentText,
        visibilidadFormador: visibilidadFormador,
        user: { author },
        date: new Date(),
      };

      if (newComment.text.trim() === "") {
        showError("Error", "No puede guardar un comentario vacío.");
      } else {
        const hasRoleFormador = user.roles.some(role => role.code === 'ROLE_FORMADOR');
        if (!hasRoleFormador) {
          // Confirmar si el comentario debe ser visible para el formador.
          Swal.fire({
            title: "Confirmar visibilidad",
            text: "¿Desea que este comentario sea visible por los formadores?",
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonText: "Sí",
            showCancelButton: true,
            cancelButtonText: "No"
          }).then(({ isConfirmed }) => {
            newComment.visibilidadFormador = isConfirmed
            onSubmit(newComment, sessionIndex);
            setInputVisible(false);
            setCommentText("");
            setEditingComment(null); // Reset editing comment after submission
          });

        } else {
          newComment.visibilidadFormador = true;
          onSubmit(newComment, sessionIndex);
          setInputVisible(false);
          setCommentText("");
          setEditingComment(null); // Reset editing comment after submission
        }
      }
    } catch (error) {
      console.error("Failed to save comment:", error);
    }
  };

  // const sortedComments = comments.sort(
  //   (a, b) => new Date(b.date) - new Date(a.date)
  // );

  const sortedComments = useMemo(() => {
    if (Array.isArray(comments) && comments.length > 0) {
      return comments.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    }
    return []; // Return an empty array if comments is not valid
  }, [comments])

  return (
    <Modal
      title={title}
      visible={isOpen}
      onCancel={onClose}
      footer={[]}
      bodyStyle={{ maxHeight: "500px", overflow: "auto", zIndex: 1050 }}
      width={window.innerWidth > 768 ? "50%" : "90%"}
      style={{ zIndex: 1050 }}
    >
      {isInputVisible ? (
        <CommentPanel
          form={form}
          commentText={commentText}
          setCommentText={setCommentText}
          validateAndSubmit={validateAndSubmit}
          setInputVisible={setInputVisible}
          isEditing={!!editingComment} // isEditing is true if editingComment is not null
          initialCommentText={editingComment ? editingComment.text : ""} // Use text of editing comment if editing, else use initial text for new comment
        />
      ) : (
        <Button
          type="primary"
          icon={<EditOutlined />}
          style={{ marginBottom: "20px" }}
          onClick={() => {
            form.resetFields();
            setInputVisible(true);
            setEditingComment(null); // Reset editing comment when creating new comment
          }}
        >
          Nuevo Comentario
        </Button>
      )}
      <CommentListPanel
        sortedComments={sortedComments}
        onDeleteComment={
          onDeleted
        }
        sessionIndex={sessionIndex}
        onEditComment={(comment) => {
          const hasRoleFormador = user.roles.some(role => role.code === 'ROLE_FORMADOR');
          if (!hasRoleFormador) {
            // Confirmar si el comentario debe ser visible para el formador.
            Swal.fire({
              title: "Confirmar visibilidad",
              text: "¿Desea que este comentario sea visible por los formadores?",
              icon: 'warning',
              showConfirmButton: true,
              confirmButtonText: "Sí",
              showCancelButton: true,
              cancelButtonText: "No"
            }).then(({ isConfirmed }) => {
              comment.visibilidadFormador = isConfirmed
              setCommentText(comment.text);
              setEditingComment(comment);
              comment.date = new Date();
              comment.visibilidadFormador = (comment.visibilidadFormador === undefined) ? false : comment.visibilidadFormador;
              comment.user = { author };
              onSubmit(comment, sessionIndex);
            });

          } else {
            setCommentText(comment.text);
            setEditingComment(comment);
            comment.date = new Date();
            comment.visibilidadFormador = (comment.visibilidadFormador === undefined) ? false : comment.visibilidadFormador;
            comment.user = { author };
            onSubmit(comment, sessionIndex);
          }

        }}
        setInputVisible={setInputVisible}
      />
    </Modal>
  );
};

export default CommentsTimelineModal;
