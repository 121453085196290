import React, {useEffect, useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';

const CustomTabContainerHours = ({ tabs, activeTab, onTabChange }) => {
  const [value, setValue] = useState(activeTab - 1); // Ajustar el índice para que coincida con el mes

  useEffect(() => {
    setValue(activeTab - 1); // Actualizar el valor cuando activeTab cambie
  }, [activeTab]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange(newValue + 1); // Ajustar el índice para que coincida con el mes
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} {...a11yProps(index)} />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <div
          key={index}
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
        >
          {value === index && tab.content}
        </div>
      ))}
    </div>
  );
};

export default CustomTabContainerHours;