import TYPES from "./types";
import {
  apiClientWithToken,
  downloadExcelFile,
  downloadFile,
} from "../services/backend";
import fileDownload from "js-file-download";

export const fetchCourseList = (filter) => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get("/cursos/list", {
        params: { ...filter },
      });

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, data: data };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log("error: " + error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const fetchCourses = (filter) => {
  return async (dispatch) => {
    try {
      const timestamp = new Date().getTime();
      const res = await apiClientWithToken().get(
        `/cursos/?timestamp=${timestamp}`,
        { params: { ...filter } }
      );

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setCourses(data));
        return { ok: true, message: data.message, data: data.list };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const fetchFilesAttachedDeals = (deal_id) => {
  return async (dispatch) => {
    try {
      const timestamp = new Date().getTime();
      const res = await apiClientWithToken().get(
        `/cursos/getDealFilesAtached/${deal_id}`
      );

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setFilesDeals(data));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const fetchNotesAttachedDeals = (deal_id) => {
  return async (dispatch) => {
    try {
      const timestamp = new Date().getTime();
      const res = await apiClientWithToken().get(
        `/cursos/getDealNotesAtached/${deal_id}`
      );

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setNotesDeals(data));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

const setCourses = (data) => ({
  type: TYPES.SET_COURSES,
  payload: data,
});

const setFilesDeals = (data) => ({
  type: TYPES.SET_FILES_DEALS,
  payload: data,
});

const setNotesDeals = (data) => ({
  type: TYPES.SET_NOTES_DEALS,
  payload: data,
});

export const downloadExcel = (filter) => {
  return async () => {
    try {
      const res = await downloadExcelFile(
        "/cursos/excel",
        "cursos.xlsx",
        filter,
        {
          titulo: "Cursos",
          cursos_tipo_id: "Cursos",
          programados: "Cursos",
        }
      );
      const blob = await res.blob();
      fileDownload(blob, "cursos.xlsx");

      return { ok: true };
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const selectCourse = (data) => ({
  type: TYPES.SELECT_COURSE,
  payload: data,
});

export const setCourseFilter = (filter) => ({
  type: TYPES.SET_COURSE_FILTER,
  payload: filter,
});

export const deleteCourse = (id) => {
  return async () => {
    try {
      const res = await apiClientWithToken().delete(`/cursos/${id}`);

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const deleteDeal = (idDeal) => {
  return async () => {
    try {
      const res = await apiClientWithToken().post(`/cursos/deleteDeal/${idDeal}`);

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

//delete deal and group
/* export const deleteGroup = (idDeal) => {
  return async () => {
    try {
      
      const queryString = idDeal.map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');

      const res = await apiClientWithToken().get(`/cursos/deleteDeal?${queryString}`);

      const { result } = res.data;
      console.log(result);

      if (result) {
        const { ok, message } = result.data;
        console.log(result.data.message);
      } else {
        console.log('Result is undefined');
      }
     /*  if (ok) {
        return { ok: true, message };
      } else {
        return { ok: false, message };
      } 
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
}; */

export const deleteGroup = (idDeal) => {
  return async () => {
    try {
      if (!Array.isArray(idDeal)) {
        throw new Error("idDeal debe ser un array");
      }

      const queryString = idDeal
        .map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      const res = await apiClientWithToken().get(`/cursos/deleteDeal?${queryString}`);

      if (!res.data || !res.data.result) {
        console.log("Estructura de respuesta inválida", res.data);
        return { ok: false, message: "Respuesta del servidor no válida" };
      }

      const { ok, message } = res.data.result.data;

      console.log(res.data.result);

      return { ok, message };
    } catch (error) {
      console.error("Error al eliminar grupo:", error);
      return { ok: false, message: "message-server-error" };
    }
  };
};


export const resetAlertDeal = (idDeal) => {
  return async () => {
    try {
      const res = await apiClientWithToken().post(`/cursos/resetAlertDeal/${idDeal}`);

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const updateDescription = (course) => {
  return async () => {
    try {
      let res;

      if (course.id) {
        res = await apiClientWithToken().put(
          `/cursos/${course.id}`,
          course
        );
      } else {
        res = await apiClientWithToken().post("/cursos", course);
      }

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};
export const sendCourse = (course) => {
  return async () => {
    try {
      let res;

      if (course.Cursos.id) {
        res = await apiClientWithToken().patch(
          `/cursos/${course.Cursos.id}`,
          course
        );
      } else {
        res = await apiClientWithToken().post("/cursos", course);
      }

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const sendOnlineCourses = (cursos) => {
  return async () => {
    try {
      let res;
      const formData = new FormData();
      Object.entries(cursos).forEach(([key, value]) => {
        if (value) {
          formData.append(`Cursos[${key}]`, value);
        }
      });

      res = await apiClientWithToken().post(
        "/cursos/cargarCursosOnline",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            AuthToken: localStorage.getItem("token") || "notoken",
          },
        }
      );

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const downloadCertificates = (id) => {
  return async () => {
    try {
      const res = await downloadFile(
        `/cursos/descargarCertificados/${id}`,
        `curso-${id}.pdf`
      );
      const blob = await res.blob();
      fileDownload(blob, `curso-${id}.pdf`);

      return { ok: true };
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const downloadDealFilesAttached = (id_fichero, apiFileName) => {
  return async () => {
    try {
      const res = await downloadFile(
        `/cursos/downloadFilesAtached/${id_fichero}`,
        apiFileName
      );
      const blob = await res.blob();
      fileDownload(blob, apiFileName);

      return { ok: true };
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const generateCertificates = (id) => {
  return async () => {
    try {
      const res = await apiClientWithToken().post(
        `/cursos/generarCertificados/${id}`
      );

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
      //dd
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const sendFaceToFaceInscriptions = (course) => {
  return async () => {
    try {
      let res;
      const formData = new FormData();
      Object.entries(course).forEach(([key, value]) => {
        if (value) {
          formData.append(`Cursos[${key}]`, value);
        }
      });
      console.log("faceToFace");
      res = await apiClientWithToken().post(
        `/cursos/inscribirAlumnosPresencial/${course.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            AuthToken: localStorage.getItem("token") || "notoken",
          },
        }
      );

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return {
          ok: false,
          message: data.message,
          alumnos_no_existentes: data.alumnos_no_existentes,
          usuarios_existentes: data.usuarios_existentes,
          errores_excel: data.errores_excel,
        };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const sendVideoInscriptions = (course) => {
  return async () => {
    try {
      let res;
      const formData = new FormData();
      Object.entries(course).forEach(([key, value]) => {
        if (value) {
          formData.append(`Cursos[${key}]`, value);
        }
      });

      res = await apiClientWithToken().post(
        `/cursos/inscribirAlumnosVideo/${course.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            AuthToken: localStorage.getItem("token") || "notoken",
          },
        }
      );

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return {
          ok: false,
          message: data.message,
          alumnos_no_existentes: data.alumnos_no_existentes,
          usuarios_existentes: data.usuarios_existentes,
          errores_excel: data.errores_excel,
        };
      }
    } catch (error) {
      console.log("sendVideoInscriptions: " + error);
      return { ok: false, message: "message-server-error" };
    }
  };
};
