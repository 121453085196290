import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formValueSelector } from 'redux-form';

import { showError } from '../../helpers/messages';
import { apiClientWithToken } from '../../services/backend';
import { fetchTrainersHours, downloadExcel } from '../../actions/trainer';
import { startLoading, endLoading } from '../../actions/ui';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import TrainerHoursSearchForm from './TrainerHoursSearchForm';
import ExcelButton from '../../components/UI/ExcelButton';
import CustomTabContainerHours from '../../components/UI/CustomTabContainerHours';
import CustomMaterialTable from '../../components/UI/CustomMaterialTable';
import Can from '../../routes/Can';


import TrainerDetailDrawer from '../../components/UI/TrainerDetailDrawer';


const ScreensTrainerListHoursReport = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [trainerHours, setTrainerHours] = useState([]);
    const [data, setData] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [activeTab, setActiveTab] = useState(new Date().getMonth() + 1);
    const [openModal, setOpenModal] = useState(false);
    const [selectedTraining, setSelectedTraining] = useState(null);


    // Estado de las horas totales por mes
    const [totalHoursPerMonth, setTotalHoursPerMonth] = useState({
        1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0
    });

    const nombre = useSelector(state => formValueSelector('trainerHoursSearchForm')(state, 'nombre'));
    const apellidos = useSelector(state => formValueSelector('trainerHoursSearchForm')(state, 'apellidos'));
    const demarcacion_id = useSelector(state => formValueSelector('trainerHoursSearchForm')(state, 'demarcacion_id'));

    const handleOpenModal = (training) => {
        setSelectedTraining(training);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedTraining(null);
    };


    const fetchData = useCallback(async (options) => {
        dispatch(startLoading());
        try {
            const timestamp = Date.now();
            const res = await apiClientWithToken().get(`/trainer-hours/?timestamp=${timestamp}`, { params: { ...options } });
            if (res.data.ok && Array.isArray(res.data.result.data.list)) {
                setTrainerHours(res.data.result.data.list);
            } else {
                setTrainerHours([]);
                showError(t('message-title-error'), 'Error');
            }
        } catch (error) {
            console.error(error);
            setTrainerHours([]);
            showError(t('message-title-error'), t('message-server-error'));
        }
        dispatch(endLoading());
    }, [dispatch, t]);

    const filter = useSelector(state => state.trainer.filter); // 🔥 Obtiene el filtro de Redux

    useEffect(() => {
        
        if (filter.email) { // Solo buscar si hay un formador seleccionado
            fetchData(filter);
        }
    }, [fetchData, filter]);

    useEffect(() => {
        const groupedData = {};
        const totalHoursPerDay = {};
        const newTotalHoursPerMonth = {};

        trainerHours.forEach(trainerHour => {
            const courseTitle = trainerHour.event.curso.titulo || 'Sin Título';
            const checkInDate = new Date(trainerHour.check_in);
            const day = checkInDate.getDate();
            const month = checkInDate.getMonth() + 1;
            const year = checkInDate.getFullYear();

            if (year === selectedYear) {  // 🔥 Filtramos por el año seleccionado
                if (month === activeTab) {  // 🔥 Solo procesamos el mes activo
                    if (!groupedData[courseTitle]) {
                        groupedData[courseTitle] = {
                            titulo: courseTitle,
                            ...Object.fromEntries(Array.from({ length: 31 }, (_, i) => [`day${i + 1}`, '-']))
                        };
                    }

                    const hoursWorked = parseFloat(trainerHour.hours_worked) || 0;
                    const existingHours = parseFloat(groupedData[courseTitle][`day${day}`]) || 0;
                    groupedData[courseTitle][`day${day}`] = (existingHours + hoursWorked).toFixed(2);

                    totalHoursPerDay[`day${day}`] = (parseFloat(totalHoursPerDay[`day${day}`]) || 0) + hoursWorked;
                }

                // 🔥 Guardamos el total de horas por mes aunque no sea el mes activo
                if (!newTotalHoursPerMonth[month]) newTotalHoursPerMonth[month] = 0;
                newTotalHoursPerMonth[month] += parseFloat(trainerHour.hours_worked) || 0;
            }
        });

        const totalRow = {
            titulo: 'Total',
            ...Object.fromEntries(Array.from({ length: 31 }, (_, i) => [
                `day${i + 1}`, totalHoursPerDay[`day${i + 1}`] ? totalHoursPerDay[`day${i + 1}`].toFixed(2) : '0.00'
            ]))
        };

        setData([...Object.values(groupedData), totalRow]);

        // 🔥 Asegurar que todos los meses tengan un valor válido (0 si no hay datos)
        setTotalHoursPerMonth(prev => ({
            ...Array.from({ length: 12 }, (_, i) => i + 1).reduce((acc, month) => {
                acc[month] = newTotalHoursPerMonth[month] || 0;
                return acc;
            }, {}),
        }));
    }, [trainerHours, selectedYear, activeTab]);  // 🔥 Se actualiza cada vez que cambia `activeTab`


    // 🔥 Evitar que el tab activo sea incorrecto al cargar la página
    useEffect(() => {
        setActiveTab(new Date().getMonth() + 1);
    }, []);


    const renderTableMothsDays = (daysInMonth) => {
        const columns = [
            {
                title: 'Formación',
                field: 'titulo',
                width: '100%',
                headerStyle: {
                    backgroundColor: '#f1f1f1',
                    color: '#333',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    textAlign: 'left',
                    padding: '12px',
                },
                cellStyle: {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    padding: '12px',
                },
                render: rowData => (
                    <span
                        style={{ cursor: 'pointer', color: '#1976D2', textDecoration: 'underline' }}
                        onClick={() => handleOpenModal(rowData)}
                    >
                        {rowData.titulo || '-'}
                    </span>
                )
            },
            ...Array.from({ length: daysInMonth }, (_, i) => ({
                title: (i + 1).toString(),
                field: `day${i + 1}`,
                headerStyle: {
                    backgroundColor: '#f1f1f1',
                    color: '#333',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    padding: '12px',
                    textAlign: 'center',
                },
                cellStyle: {
                    padding: '12px',
                    textAlign: 'center',
                },
                render: rowData => <span>{rowData[`day${i + 1}`] || '0.00'}</span>
            }))
        ];

        const tableData = [...data];
        const rowStyleTotal = {
            fontWeight: 'bold',
            backgroundColor: '#f0f0f0',
        };

        return (
            <CustomMaterialTable
                title="Horas Trabajadas"
                columns={columns}
                data={tableData}
                options={{
                    search: true,
                    paging: false,
                    headerStyle: {
                        backgroundColor: '#f1f1f1',
                        color: '#333',
                        fontSize: '16px',
                        textAlign: 'center',
                    },
                    rowStyle: rowData =>
                        rowData.titulo === 'Total' ? rowStyleTotal : { backgroundColor: '#ffffff' },
                    actionsColumnIndex: -1,
                }}
            />
        );
    };

    const handleExcel = async () => {
        dispatch(startLoading());
        try {
            const res = await dispatch(downloadExcel({ nombre, apellidos, demarcacion_id }));
            if (!res.ok) showError(t('message-title-error'), t(res.message));
        } finally {
            dispatch(endLoading());
        }
    };

    const tabs = [
        { label: `Enero (${totalHoursPerMonth[1].toFixed(2)} horas)`, content: renderTableMothsDays(31), month: 1 },
        { label: `Febrero (${totalHoursPerMonth[2].toFixed(2)} horas)`, content: renderTableMothsDays(28), month: 2 },
        { label: `Marzo (${totalHoursPerMonth[3].toFixed(2)} horas)`, content: renderTableMothsDays(31), month: 3 },
        { label: `Abril (${totalHoursPerMonth[4].toFixed(2)} horas)`, content: renderTableMothsDays(30), month: 4 },
        { label: `Mayo (${totalHoursPerMonth[5].toFixed(2)} horas)`, content: renderTableMothsDays(31), month: 5 },
        { label: `Junio (${totalHoursPerMonth[6].toFixed(2)} horas)`, content: renderTableMothsDays(30), month: 6 },
        { label: `Julio (${totalHoursPerMonth[7].toFixed(2)} horas)`, content: renderTableMothsDays(31), month: 7 },
        { label: `Agosto (${totalHoursPerMonth[8].toFixed(2)} horas)`, content: renderTableMothsDays(31), month: 8 },
        { label: `Septiembre (${totalHoursPerMonth[9].toFixed(2)} horas)`, content: renderTableMothsDays(30), month: 9 },
        { label: `Octubre (${totalHoursPerMonth[10].toFixed(2)} horas)`, content: renderTableMothsDays(31), month: 10 },
        { label: `Noviembre (${totalHoursPerMonth[11].toFixed(2)} horas)`, content: renderTableMothsDays(30), month: 11 },
        { label: `Diciembre (${totalHoursPerMonth[12].toFixed(2)} horas)`, content: renderTableMothsDays(31), month: 12 },
    ];

    return (
        <PageContainer>
            <PageTitle title={t('screens-trainer-list-hours-title')}>
                <Can perform="FormadoresHomologados:excel" yes={() => <ExcelButton onPressButton={handleExcel} />} />
            </PageTitle>

            <TrainerHoursSearchForm />
            <CustomTabContainerHours tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} />

            <TrainerDetailDrawer
                open={openModal}
                onClose={handleCloseModal}
                training={selectedTraining}
                trainerHours={trainerHours} // 🔥 Pasar los datos de horas trabajadas al modal
            />
        </PageContainer>
    );
};

export default ScreensTrainerListHoursReport;
