import TYPES from './types';
import { apiClientWithToken, downloadExcelFile, apiClientWithoutToken } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchTrainers = (filter) => {
    return async (dispatch) => {
        try {
            const timestamp = Date.now(); // replace with your timestamp if needed
            
            const res = await apiClientWithToken().get(
                `/trainers/?timestamp=${timestamp}`, { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setTrainers(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setTrainers = (data) => ({
   type: TYPES.SET_TRAINERS, payload: data
});

export const selectTrainer = (data) => ({
    type: TYPES.SELECT_TRAINER, payload: data
});

export const fetchTrainersHours = (filter) => {
    return async (dispatch) => {
        try {
            const timestamp = Date.now(); // replace with your timestamp if needed
            
            const res = await apiClientWithToken().get(
                `/trainer-hours/?timestamp=${timestamp}`, { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setTrainerHours(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setTrainerHours = (data) => ({
    type: TYPES.SET_TRAINER_HOURS, payload: data
 });

export const downloadExcel = (filter) => {
    return async () => {
        try {

            const res = await downloadExcelFile(
                '/trainers/excel', 
                'formadores.xlsx',
                filter,{
                    nombre: 'Trainers',
                    apellidos: 'Trainers',
                    email: 'Trainers',
                    demarcacion_id: 'Trainers'
                }
            );
            const blob = await res.blob();
            fileDownload(blob, 'formadores.xlsx');

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const confirmarDisponibilidad = (trainer) => {
    return async (dispatch) => {
        try {
            const res = await apiClientWithoutToken().post(
                '/events/confirmarDisponibilidad', trainer
            );
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }      
    };
};

export const setTrainerFilter = (filter) => ({
    type: TYPES.SET_TRAINER_FILTER, payload: filter
});

export const deleteTrainer = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/trainers/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendTrainer = (trainer) => {
    return async () => {
        try {
            let res;

            if (trainer.Trainers.id) {
                res = await apiClientWithToken().put(
                    `/trainers/${trainer.Trainers.id}`,
                    trainer
                );
            } else {
                res = await apiClientWithToken().post(
                    '/trainers', trainer
                );
            }
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};