import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from "react-i18next";
import AlumnosInscriptosResultsCard from './alumnosInscriptosResultsCard';

const OpenInscriptionsModal = ({ courseId, onClose }) => {
    const { t } = useTranslation();

    return (
        <Modal
            open={true}
            footer={null}
            onCancel={onClose}
            centered
            width="75%"
            style={{ maxWidth: '100%', top: 20 }}
            styles={{ body: { padding: 0 } }}
        >
           
           { <AlumnosInscriptosResultsCard
                courseTitle={t('screens.planner.eventDetailsModal.alumnosInscriptos')}
                courseId={courseId}
            />}
        </Modal>
    );
};

export default OpenInscriptionsModal;