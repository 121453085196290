import React from 'react';
import { Drawer, IconButton, Typography, Divider } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const TrainerDetailDrawer = ({ open, onClose, training, trainerHours }) => {
    return (
        <Drawer 
            anchor="right" 
            open={open} 
            onClose={onClose} 
        >
            <div style={{ width: '100%', padding: '20px', position: 'relative', paddingTop: '50px' }}>
                {/* Botón de cierre */}
                <IconButton onClick={onClose} style={{ position: 'absolute', top: 10, right: 10 }}>
                    <CloseIcon />
                </IconButton>

                {/* Título */}
                <Typography variant="h5" gutterBottom>
                    {training?.titulo || 'Detalle de Formación'}
                </Typography>
                <Divider style={{ marginBottom: '10px' }} />

                {/* Mostrar el primer evento y sus horas */}
                {trainerHours && trainerHours.length > 0 ? (
                    trainerHours.map((hourRecord, index) => (
                        <div key={index} style={{ marginBottom: '15px' }}>
                            <Typography variant="h6">{hourRecord.event.curso.codigo_alumno_grupo}</Typography>
                            <Typography variant="body1">
                                <strong>Horas trabajadas:</strong> {hourRecord.totalHoras || 'No disponible'}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Fecha de inicio:</strong> {hourRecord.event.f_inicio || 'No disponible'}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Fecha de fin:</strong> {hourRecord.fechaFin || 'No disponible'}
                            </Typography>
                            <Divider style={{ margin: '10px 0' }} />
                        </div>
                    ))
                ) : (
                    <Typography variant="body1">No hay registros de horas.</Typography>
                )}
            </div>
        </Drawer>
    );
};

export default TrainerDetailDrawer;
